import { LeftOutlined, PieChartOutlined } from '@ant-design/icons'
import { Button, Menu } from 'antd'
import React from 'react'

type MenuItem = {
  label: React.ReactNode
  key: React.Key
  icon?: React.ReactNode
  children?: MenuItem[]
  type?: 'group'
}

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: 'group'
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem
}

const items: MenuItem[] = [getItem('Global Frequency Capping', '1', <PieChartOutlined />)]

const SubMenu: React.FC<{ collapsed: boolean; toggleCollapsed: () => void }> = ({
  collapsed,
  toggleCollapsed,
}) => {
  if (collapsed) return null
  return (
    <div
      style={{
        maxWidth: 256,
        backgroundColor: '#fff',
        position: 'relative',
      }}
    >
      <Menu
        className="p-4 pt-12"
        defaultSelectedKeys={['1']}
        mode="inline"
        theme="light"
        inlineCollapsed={collapsed}
      >
        {items.map((item) => (
          <Menu.Item key={item.key}>{item.label}</Menu.Item>
        ))}
      </Menu>

      <div
        onClick={toggleCollapsed}
        style={{
          marginBottom: 16,
          position: 'absolute',
          bottom: 0,
          padding: 0,
          cursor: 'pointer',
          width: '100%',
          borderTop: '1px solid #e8e8e8',
          textAlign: 'right',
          paddingRight: 16,
          paddingTop: 16,
        }}
      >
        <LeftOutlined />
      </div>
    </div>
  )
}

export default SubMenu
