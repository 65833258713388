import { action, thunk, Action, Thunk } from 'easy-peasy'

export interface States {
  isMenuCollapsed: boolean
  isSubMenuCollapsed: boolean
}

export interface StoreProps extends States {
  setMenuCollapse: Action<StoreProps>
  setSubMenuCollapse: Action<StoreProps>
}

const initState = {
  isMenuCollapsed: false,
  isSubMenuCollapsed: false,
}

const store: StoreProps = {
  ...initState,
  setMenuCollapse: action((state) => {
    state.isMenuCollapsed = !state.isMenuCollapsed
  }),
  setSubMenuCollapse: action((state) => {
    state.isSubMenuCollapsed = !state.isSubMenuCollapsed
  }),
}

export default store
